(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("geoblaze", [], factory);
	else if(typeof exports === 'object')
		exports["geoblaze"] = factory();
	else
		root["geoblaze"] = factory();
})((typeof window !== 'undefined' ? window : typeof self !== 'undefined' ? self : typeof global !== 'undefined' ? global : typeof this !== 'undefined' ? this : undefined), function() {
return 